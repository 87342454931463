<template>
  <div class="menu-filters" :class="{
      'menu-filters--controls-enabled':
        $store.getters.functionalityOrderEnabled ||
        $store.getters.functionalityPayGoEnabled,
    }">
    <MenuHeader :show-category-slider="false" />
    <LayoutCard
      v-if="displayFilters"
      rounded
      :hide-background="!!$store.getters.customizationMenuHideLayoutCards"
      :use-custom-background-color="true"
    >
      <v-row dense>
        <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
          <v-card
            class="menu-filters__card"
            flat
            rounded="lg"
            :cy-data="`menu-filter-${card.title}`"
            @click="() => onCardClick(card)"
          >
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.2)"
              height="150px"
            >
            </v-img>
            <v-card-title
              class="tp-text-subtitle menu-filters__card-text"
              :class="{'menu-filters__card-text--center': !!$store.getters.customizationMenuFilterCardsTextCenter }"
            >
              {{ $t(card.title) }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </LayoutCard>
    <MoreInformation />
    <MenuControls />
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import MenuHeader from '@/components/Menu/MenuHeader.vue';
import MoreInformation from '@/components/MoreInformation.vue';
import MenuControls from '@/components/MenuControls.vue';


export default {
  components: {
    LayoutCard,
    MenuHeader,
    MoreInformation,
    MenuControls
  },
  data() {
    return {
      pageTitle: 'Loading menu - Greet.menu',
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    filters() {
      return this.$store.getters.restaurantMenuFilters;
    },
    displayFilters() {
      return this.filters.length > 0;
    },
    cards() {
      return this.filters.map(filter => ({
        title: filter.name,
        src: filter.image,
        flex: filter.flex,
        categoryIds: filter.category_ids,
      }));
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;

    if (this.$store.state.restaurant.id === null) {
      await this.$store.dispatch('fetchSessionData', {session: sessionToken});
      this.pageTitle = this.$store.state.restaurant.name + ' - Greet.menu';
    }
  },
  methods: {
    onCardClick(filter) {
      this.$store.dispatch('setActiveMenuFilters', filter.categoryIds);

      this.$router.push({
        name: 'Menu',
        params: { session: this.session },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-filters {
  width: 100vw;
  max-width: $max-app-width;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--menu-background-color);
  background-image: var(--menu-background-image);
  background-position: top;
  background-size: cover;
  background-repeat: revert;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &__card {
    background-color: var(--menu-filter-cards-color) !important;
  }
  &__card-text {
    color: var(--menu-filter-cards-text-color) !important;
    &--center {
      justify-content: center!important;
    }
  }

  &--controls-enabled {
    padding-bottom: 88px;
  }
}
</style>
