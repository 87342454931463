var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.hasPaymentProviders)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t('components.paymentMethod.noPaymentProvidersMessage')))])]):_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWallet && _vm.stripeEnabled),expression:"showWallet && stripeEnabled"}]},[_c('DividerWithText',{attrs:{"text":_vm.$t('components.paymentMethod.prButtonUpperDivider'),"for":"prButton"}}),_c('div',{ref:"prButton",staticClass:"my-5"}),(_vm.externalGPayAvailable)?_c('div',{staticClass:"my-5"},[_c('button',{staticClass:"gpay-button black order en",attrs:{"type":"button","aria-label":"Order with GPay"},on:{"click":_vm.payWithExternalWallet}})]):_vm._e(),_c('DividerWithText',{attrs:{"text":_vm.$t('components.paymentMethod.prButtonLowerDivider'),"for":"prButton"}})],1),_c('v-radio-group',{attrs:{"column":"","hide-details":""},on:{"change":_vm.onPaymentMethodChange},model:{value:(_vm.otherPaymentMethod),callback:function ($$v) {_vm.otherPaymentMethod=$$v},expression:"otherPaymentMethod"}},[(_vm.neopayBlikEnabled)?_c('v-radio',{staticClass:"py-3",attrs:{"color":_vm.color,"value":_vm.providers.NEOPAY_BLIK,"ripple":false,"data-cy":"payment-method-neopay-blik"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center w-100"},[_c('div',[_c('div',{staticClass:"tp-text-subtitle-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.blikTitle'))+" ")]),_c('div',{staticClass:"tp-text-label-2"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.blikDescription'))+" ")])]),_c('img',{attrs:{"src":require(`@/assets/images/payments/blik-logo.svg`),"width":"40"}})])]},proxy:true}],null,false,2422042768)}):_vm._e(),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
            [_vm.providers.NEOPAY_BLIK].includes(
              _vm.otherPaymentMethod
            )
          ),expression:"\n            [providers.NEOPAY_BLIK].includes(\n              otherPaymentMethod\n            )\n          "}],attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"tp-text-body-gray"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.blikSubtitle'))+" ")])])],1)],1),(_vm.montonioEnabled)?_c('v-radio',{staticClass:"py-3",attrs:{"color":_vm.color,"value":_vm.providers.MONTONIO,"ripple":false,"data-cy":"payment-method-montonio"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center w-100"},[_c('div',{staticClass:"d-flex justify-space-between align-center w-100"},[_c('div',[_c('div',{staticClass:"tp-text-subtitle-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.banklinkTitle'))+" ")]),_c('div',{staticClass:"tp-text-label-2"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.banklinkDescription'))+" ")])]),_c('img',{attrs:{"src":require(`@/assets/images/icons/bank-icon.svg`)}})])])]},proxy:true}],null,false,3120613803)}):_vm._e(),(_vm.neopayEnabled)?_c('v-radio',{staticClass:"py-3",attrs:{"color":_vm.color,"value":_vm.providers.NEOPAY,"ripple":false,"data-cy":"payment-method-neopay"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center w-100"},[_c('div',[_c('div',{staticClass:"tp-text-subtitle-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.banklinkTitle'))+" ")]),_c('div',{staticClass:"tp-text-label-2"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.banklinkDescription'))+" ")])]),_c('img',{attrs:{"src":require(`@/assets/images/icons/bank-icon.svg`)}})])]},proxy:true}],null,false,1171429343)}):_vm._e(),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
            [_vm.providers.MONTONIO, _vm.providers.NEOPAY].includes(
              _vm.otherPaymentMethod
            )
          ),expression:"\n            [providers.MONTONIO, providers.NEOPAY].includes(\n              otherPaymentMethod\n            )\n          "}],attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"tp-text-body-gray"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.montonioSubtitle'))+" ")])])],1)],1),(_vm.stripeEnabled)?_c('v-radio',{staticClass:"py-3",attrs:{"value":_vm.providers.STRIPE,"ripple":false,"data-cy":"payment-method-stripe-card","color":_vm.color},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center w-100"},[_c('div',[_c('div',{staticClass:"tp-text-subtitle-medium"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.cardTitle'))+" ")]),_c('div',{staticClass:"tp-text-label-2"},[_vm._v(" "+_vm._s(_vm.$t('components.paymentMethod.cardDescription'))+" ")])]),_c('div',{staticClass:"d-flex"},[_c('img',{attrs:{"src":require(`@/assets/images/payments/visa.svg`)}}),_c('img',{staticClass:"pl-1",attrs:{"src":require(`@/assets/images/payments/mastercard.svg`)}})])])]},proxy:true}],null,false,4137976093)}):_vm._e(),_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.otherPaymentMethod === _vm.providers.STRIPE),expression:"otherPaymentMethod === providers.STRIPE"}],attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{ref:"card",staticClass:"mb-3 mx-1"})])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }