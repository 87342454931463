export const defaultTheme = [
  { property: '--color-primary', value: '#e85941' },
  { property: '--color-text-main', value: '#000000' },
  { property: '--color-button-gradient-top', value: '#e85941' },
  { property: '--color-button-gradient-bottom', value: '#ff3131' },
  { property: '--menu-background-color', value: '#f3f4f6' },
  { property: '--menu-background-image', value: 'none' },
  { property: '--menu-short-description-color', value: '#5f5f5f' },
  { property: '--menu-short-description-weight', value: 400 },
  { property: '--menu-short-description-size', value: '14px' },
  { property: '--menu-category-description-color', value: '#5f5f5f' },
  { property: '--menu-font-family', value: "'Poppins', sans-serif" },
  { property: '--menu-categories-font-family', value: "'Poppins', sans-serif" },
  {
    property: '--menu-item-description-font-family',
    value: "'Poppins', sans-serif",
  },
  { property: '--menu-item-description-font-style', value: 'normal' },
  { property: '--menu-item-description-letter-spacing', value: 0 },
  { property: '--menu-items-font-weight', value: 600 },
  { property: '--menu-items-price-font-weight', value: 600 },
  { property: '--menu-items-price-font-color', value: '#000000' },
  { property: '--menu-items-price-font-size', value: '14px' },
  { property: '--menu-items-price-font-style', value: 'normal' },
  { property: '--menu-categories-font-size', value: '20px' },
  { property: '--menu-categories-font-weight', value: 600 },
  { property: '--menu-category-description-font-size', value: '14px' },
  { property: '--menu-category-description-font-weight', value: 400 },
  { property: '--menu-category-description-font-color', value: '#5f5f5f' },
  { property: '--menu-categories-slider-font-weight', value: 500 },
  { property: '--menu-categories-font-color', value: '#000000' },
  { property: '--menu-categories-background-color', value: '#fff' },
  { property: '--menu-categories-letter-spacing', value: 'initial' },
  { property: '--menu-categories-font-style', value: 'normal' },
  { property: '--menu-items-font-color', value: '#000000' },
  { property: '--menu-items-font-size', value: '16px' },
  { property: '--menu-items-font-style', value: 'normal' },
  { property: '--menu-category-slider-inactive-color', value: '#5f5f5f' },
  { property: '--select-screen-restaurant-title-color', value: '#000' },
  { property: '--select-screen-buttons-background-color', value: '#fff' },
  { property: '--select-screen-buttons-text-color', value: '#000' },
  { property: '--select-screen-buttons-border-radius', value: '10px' },
  { property: '--menu-items-divider-color', value: '#000000' },
  {
    property: '--quick-access-menu-buttons-background-color',
    value: '#f5f5f5',
  },
  { property: '--menu-filter-cards-color', value: '#F9F9F9' },
  { property: '--menu-filter-cards-text-color', value: '#000' },
  { property: '--menu-category-divider-color', value: '#5f5f5f' },
  { property: '--menu-category-divider-height', value: '2px' },
  {
    property: '--menu-items-price-font-family',
    value: "'Poppins', sans-serif",
  },
  { property: '--menu-items-title-line-height', value: '1.25em' },
  { property: '--menu-items-title-max-height', value: '2.5em' },
  { property: '--menu-items-title-letter-spacing', value: 0 },
  { property: '--menu-restaurant-title-font-size', value: '24px' },
  { property: '--menu-restaurant-title-font-weight', value: 700 },
  { property: '--menu-restaurant-title-font-color', value: '#000000' },
  { property: '--menu-restaurant-description-font-size', value: '14px' },
  { property: '--menu-restaurant-description-font-weight', value: 400 },
  { property: '--menu-restaurant-description-font-color', value: '#5F5F5F' },
];
