<template>
  <div
    id="menu-collapsing-top-bar"
    :style="cssProps"
    class="collapsing-top-bar d-flex flex-column justify-space-between"
    :class="{
      'collapsing-top-bar--menu-background-color':
        $store.getters.customizationMenuBackgroundColor,
    }"
  >
    <v-container>
      <h5
        class="collapsing-top-bar__restaurant-name tp-text-subtitle-bold mt-3 d-flex align-center"
        :class="{
          'collapsing-top-bar__restaurant-name--white':
            $store.getters.customizationMenuDarkMode,
          'ml-12': showBack
        }"
      >
        <img
          v-if="$store.getters.customizationLogo"
          class="mr-2"
          style="max-height: 21px;"
          :src="$store.getters.customizationLogo"
        />
        <span class="name-container" @click="scrollToTop">
          {{ restaurantName }}
        </span>
      </h5>
    </v-container>
    <category-slider v-if="showCategorySlider"></category-slider>
  </div>
</template>

<script>
import CategorySlider from '@/components/Header/CategorySlider.vue';

export default {
  components: {
    CategorySlider,
  },
  props: {
    showCategorySlider: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    restaurantName() {
      return this.$store.state.restaurant.name;
    },
    cover() {
      return this.$store.state.restaurant.cover;
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    width() {
      return this.loyaltyProgramEnabled
        ? 'calc(100% - 140px)'
        : 'calc(100% - 96px)';
    },
    cssProps() {
      return {
        '--width': this.width,
      };
    },
  },
  mounted() {
    const quickAccessMenuExists = this.$store.getters.quickAccessMenu.length > 0;

    if (!quickAccessMenuExists) {
      // Add sticky class to menu-top-bar for animations when scrolling after menu header image
      const el = document.querySelector('#menu-header-image');
      const observer = new IntersectionObserver(
        ([e]) => {
          const findTopBarInterval = setInterval(() => {
            if (document.querySelector('#menu-collapsing-top-bar') !== null) {
              if (e.intersectionRatio <= 0.2) {
                document
                  .querySelector('#menu-collapsing-top-bar')
                  .classList.add('sticky');
                this.$store.dispatch('sendAnalyticsEvent', {
                  event: 'collapsing_top_bar_opened',
                });
              } else {
                document
                  .querySelector('#menu-collapsing-top-bar')
                  .classList.remove('sticky');
                this.$store.dispatch('closeCategoryDropdown');
              }
              clearInterval(findTopBarInterval);
            }
          }, 100);
        },
        { threshold: [0.2] }
      );
      observer.observe(el);
    } else {
      const el = document.querySelector('#quick-access-menu-block');
      const observer = new IntersectionObserver(
        ([e]) => {
          const findTopBarInterval = setInterval(() => {
            if (document.querySelector('#menu-collapsing-top-bar') !== null) {
              if (e.intersectionRatio <= 0.2) {
                document
                  .querySelector('#menu-collapsing-top-bar')
                  .classList.add('sticky');
              } else {
                document
                  .querySelector('#menu-collapsing-top-bar')
                  .classList.remove('sticky');
                this.$store.dispatch('closeCategoryDropdown');
              }
              clearInterval(findTopBarInterval);
            }
          }, 100);
        },
        { threshold: [0.2] }
      );
      observer.observe(el);
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="scss">
.name-container {
  width: var(--width);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collapsing-top-bar {
  position: fixed;
  width: 100%;
  max-width: $max-app-width;
  background: white;
  z-index: 105;
  max-height: 106px;
  min-height: 64px;
  top: -106px;
  transition: top 200ms ease-in-out;
  overflow: hidden;

  &.sticky {
    top: 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  }

  &--menu-background-color {
    background: var(--menu-background-color);
    background-image: var(--menu-background-image);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__restaurant-name {
    font-family: var(--menu-font-family) !important;
    font-weight: var(--menu-restaurant-title-font-weight) !important;
    color: var(--menu-restaurant-title-font-color) !important;
    &--white {
      color: #fff!important;
    }
  }
}
</style>
