<template>
  <div>
    <v-radio-group v-model="radios" hide-details mandatory>
      <div
        v-for="variation in radios"
        :key="variation.id"
        class="d-flex align-center justify-space-between mb-6 variation"
        :class="{ 'opacity-50': !variation.in_stock }"
      >
        <div>
          <div class="tp-text-body-2">
            {{ variation.name }}
          </div>
          <div class="tp-text-caption d-flex align-center flex-row">
            <span>
              {{ getFormattedVariationPrice(variation) }}
            </span>
            <loyalty-price
              v-if="variation.loyalty_price"
              class="ml-3"
              :with-top-margin="false"
              :price="variation.loyalty_price"
              :smaller-text="true"
              :bigger="true"
            />
          </div>
        </div>
        <v-radio
          v-if="variation.in_stock && showRadioOptions"
          :label="variation.name"
          :value="variation"
          :ripple="false"
          :disabled="!variation.in_stock"
        >
          <template #label>
            <div />
          </template>
        </v-radio>
        <span v-else-if="!variation.in_stock" class="mr-2 tp-text-body">
          {{ $t('components.menuItem.outOfStock') }}
        </span>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import LoyaltyPrice from '@/components/MenuItem/LoyaltyPrice.vue';

export default {
  components: {
    LoyaltyPrice,
  },
  props: { variations: { type: Array, required: true } },
  computed: {
    radios: {
      get() {
        return this.variations;
      },
      set(value) {
        this.$emit('update:variations', value);
        this.$emit('on-change', value);
      },
    },
    showRadioOptions() {
      return (
        this.$store.getters.functionalityOrderEnabled &&
        !this.$store.getters.ordersTemporarilyDisabled
      );
    },
  },
  methods: {
    getFormattedVariationPrice(variation) {
      return this.$store.getters.getFormattedPrice(variation.price);
    },
  },
};
</script>

<style scoped lang="scss">
.variation:last-child {
  margin-bottom: 0 !important;
}

.opacity-50 {
  opacity: 0.5;
}
</style>
