<template>
  <div
    :id="`category-${category.hash}`"
    class="category-section"
    :class="{
      'category-section-banner': isCategoryTypeBanner && category.banner,
    }"
  >
    <div
      v-if="isCategoryTypeBanner && category.banner"
      class="category__banner"
      :class="{
        'category__banner--full-width':
          $store.getters.customizationMenuBannerFullWidth,
      }"
    >
      <img :src="$t(category.banner)" width="100%"/>
    </div>
    <div
      v-else-if="!$store.getters.customizationMenuHideCategoryTitle"
      class="mb-6"
    >
      <div
        v-if="$store.getters.customizationMenuCategoryDoubleDividerEnabled"
        class="category__divider mb-3"
      ></div>
      <div
        class="category__title tp-text-title-bold"
        :class="{
          'category__title--center':
            $store.getters.customizationMenuCategoriesCenter,
          'category__title--with-background':
            $store.getters.customizationMenuCategoriesBackgroundColor,
        }"
        :style="{ color: rotatingFontColor }"
      >
        {{ $t(category.name) }}
      </div>
      <div
        v-if="category.description"
        class="category__description tp-text-body-gray mt-3"
        :class="{
          'category__description--center':
            $store.getters.customizationMenuCategoriesCenter,
        }"
        v-html="$t(category.description)"
      ></div>
      <div
        v-if="
          $store.getters.customizationMenuCategoryDividerEnabled ||
            $store.getters.customizationMenuCategoryDoubleDividerEnabled
        "
        class="category__divider mt-3"
        :class="{
          'category__divider--dashed':
            $store.getters.customizationMenuCategoryDividerStyle === 'dashed',
          'category__divider--dotted':
            $store.getters.customizationMenuCategoryDividerStyle === 'dotted',
        }"
      ></div>
    </div>
    <div v-if="itemsWithoutSubcategory.length > 0">
      <menu-item
        v-for="(item, index) in itemsWithoutSubcategory"
        :key="index"
        :data-cy="`category-${category.id}-item-${index}`"
        v-bind="item"
        :full-width-info="showFullWidthInfo"
        :display-as-out-of-stock="
          !isCategoryItemsAvailable &&
            category.availability.type === 'availability'
        "
        :rotating-category-color="rotatingFontColor"
      ></menu-item>
    </div>
    <subcategory
      v-for="(subcategory, key) in subcategories"
      :key="'category' + key"
      :class="{ 'mt-4': key !== 0 }"
      v-bind="subcategory"
    ></subcategory>
  </div>
</template>

<script>
import MenuItem from '@/components/MenuItem/MenuItem.vue';
import Subcategory from '@/components/Subcategory.vue';
import categoryHasImages from '../helpers/categoryHasImages.js';
import { normalizeNewLines } from '../utils/strings';

export default {
  components: {
    MenuItem,
    Subcategory,
  },
  props: {
    category: Object,
    index: Number,
  },
  data() {
    return {
      rotatingFontColor: null,
    };
  },
  computed: {
    normalizedCategoryDescription() {
      return normalizeNewLines(this.category.description);
    },
    items() {
      return this.$store.state.items.filter(item => {
        return item.categoryHash === this.category.hash;
      });
    },
    itemsWithoutSubcategory() {
      return this.$store.state.items.filter(item => {
        return item.categoryHash === this.category.hash && !item.subcategory;
      });
    },
    showFullWidthInfo() {
      return !categoryHasImages(this.itemsWithoutSubcategory);
    },
    subcategories() {
      return this.$store.state.subcategories.filter(subcategory => {
        return subcategory.category === this.category.name;
      });
    },
    isCategoryItemsAvailable() {
      return (
        !this.category.availability.enabled ||
        (this.category.availability.enabled &&
          this.category.availability.isAvailable)
      );
    },
    isCategoryTypeBanner() {
      return this.category.type === 'banner';
    },
  },
  mounted() {
    const rotatingFontColors = this.$store.getters
      .customizationMenuCategoriesRotatingFontColors;
    if (rotatingFontColors) {
      const categoryWithoutBannersIndex = this.$store.getters.categories.findIndex((category) => {
        return category.id === this.category.id;
      });
      if (categoryWithoutBannersIndex > -1) {
        this.rotatingFontColor =
          rotatingFontColors[categoryWithoutBannersIndex % rotatingFontColors.length];
      }
    }
  },
};
</script>

<style lang="scss">
.category {
  &__title {
    font-size: var(--menu-categories-font-size) !important;
    font-family: var(--menu-categories-font-family) !important;
    font-weight: var(--menu-categories-font-weight) !important;
    letter-spacing: var(--menu-categories-letter-spacing) !important;
    font-style: var(--menu-categories-font-style) !important;
    &--center {
      text-align: center;
    }
    &--with-background {
      background: var(--menu-categories-background-color) !important;
      margin-left: -16px;
      margin-right: -16px;
      padding: 7px 0;
    }
    color: var(--menu-categories-font-color);
  }
  &__description {
    font-family: var(--menu-font-family) !important;
    font-size: var(--menu-category-description-font-size) !important;
    font-weight: var(--menu-category-description-font-weight) !important;
    color: var(--menu-category-description-color) !important;
    &--center {
      text-align: center;
    }
    white-space: pre-line;
  }
  &__divider {
    border-bottom: var(--menu-category-divider-height) solid
      var(--menu-category-divider-color);
    &--dashed {
      border-style: dashed;
    }
    &--dotted {
      border-style: dotted;
    }
  }
  &__banner {
    display: flex;
    .lazy-img {
      width: 100%;
      border-radius: 5px;
    }

    &--full-width {
      margin: -16px;
    }
  }
}
</style>
