<template>
  <LayoutCard
    v-if="tipsEnabled"
    data-cy="tip-personnel"
    :title="$t('components.tipPersonnel.title')"
    rounded
  >
    <div class="tp-text-body grey--text">
      {{ subtitle }}
    </div>
    <WaiterCarousel v-if="!collective" @on-change="changeWaiter" />
    <v-expand-transition>
      <v-row v-if="showCta" no-gutters>
        <h4 v-if="!collective" class="pt-4">
          {{ $t('components.tipPersonnel.pickAmountTitle') }}
        </h4>
        <TipsPicker :tips="tipChips" @on-change="onTipsChange" />
        <div v-if="disclaimer" class="tp-text-body mt-3 grey--text">
          {{ disclaimer }}
        </div>
      </v-row>
    </v-expand-transition>
  </LayoutCard>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import WaiterCarousel from '@/components/Tips/WaiterCarousel.vue';
import TipsPicker from '@/components/Cart/TipsPicker.vue';
import { tipChipsByPercentage } from '@/utils/tips';
import { debounce } from 'lodash';

export default {
  components: {
    LayoutCard,
    WaiterCarousel,
    TipsPicker,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    subtotalAmount: {
      type: Number,
      default: 0,
    },
    uniqueOrderNumber: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    selectedPersonnelId: null,
    tipAmount: null,
  }),
  computed: {
    personnelList() {
      return this.$store.getters.personnelList;
    },
    collective() {
      return this.$store.getters.functionalityCollectiveTipsEnabled;
    },
    tipsEnabled() {
      const hasPersonnel = this.personnelList.length > 0;
      const tipsEnabled = this.$store.getters.functionalityTipsEnabled;

      return (
        ((hasPersonnel && !this.collective) || this.collective) &&
        tipsEnabled &&
        this.amount > 0
      );
    },
    showCta() {
      if (this.collective) {
        return true;
      }

      return this.selectedPersonnelId !== null;
    },
    order() {
      return this.$store.getters.getPOSOrderByUniqueOrderNumber(
        this.uniqueOrderNumber
      );
    },
    guestCount() {
      return this.order?.guest_count || 1;
    },
    guestCountThreshold() {
      return this.$store.getters
        .customizationCollectiveTipsRequiredGuestCountThreshold;
    },
    requiredTipsConfig() {
      const guestCountThreshold = this.guestCountThreshold;

      if (!guestCountThreshold) {
        return undefined;
      }

      return {
        guestCountThreshold: guestCountThreshold,
        preselectedTipPercentage: 0.1,
      };
    },
    tipChips() {
      const formatPrice = amount =>
        this.$store.getters.getFormattedPrice(amount, 0);
      const tipChips = tipChipsByPercentage({
        formatPrice,
        amount: this.amount,
        guestCount: this.guestCount,
        requiredTipsConfig: this.requiredTipsConfig,
      });

      return tipChips;
    },
    subtitle() {
      return this.collective
        ? this.$t('components.tipPersonnel.collective.subtitle')
        : this.$t('components.tipPersonnel.subtitle');
    },
    disclaimer() {
      const guestCountThreshold = this.guestCountThreshold;

      if (!guestCountThreshold || this.amount <= 1.8) {
        return undefined;
      }

      return this.$t('components.tipPersonnel.minTipAmountDisclaimer', {
        guestCount: guestCountThreshold,
      });
    },
  },
  watch: {
    selectedPersonnelId() {
      this.$emit('on-change', {
        personnelId: this.selectedPersonnelId,
        tipAmount: this.tipAmount,
      });
    },
    tipAmount: {
      handler: debounce(function(amount) {
        this.$emit('on-change', {
          personnelId: this.selectedPersonnelId,
          tipAmount: amount,
        });
      }, 300),
      immediate: true,
    },
  },
  async mounted() {
    // NOTE: not sure if this is the best place to fetch personnel
    await this.$store.dispatch('fetchPersonnel');
  },
  methods: {
    changeWaiter(selectedPersonnelId) {
      this.selectedPersonnelId = selectedPersonnelId;
    },
    onTipsChange(amount) {
      this.tipAmount = amount;
    },
  },
};
</script>

<style lang="scss" scoped></style>
