import { activateOrderBatchRequest } from './api';

export default {
  async activateOrderBatch({ rootState }, { uniqueOrderNumber }) {
    try {
      const response = await activateOrderBatchRequest({
        session: rootState.session,
        uniqueOrderNumber: uniqueOrderNumber,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
