<template>
  <div class="search">
    <SearchHeader
      ref="searchInput"
      v-model="search"
      :placeholder="
        counter >= 3 ? counter : $t('screens.search.searchPlaceholder')
      "
      @onBackClick="goBack"
    />
    <LayoutCard rounded>
      <div v-if="menuItems.length >= 1 && search !== ''">
        <MenuItem
          v-for="(item, index) in menuItems"
          :key="index"
          v-bind="item"
          :full-width-info="showFullWidthInfo"
          :in-search-page="true"
        />
      </div>
      <div
        v-else-if="search === ''"
        class="d-flex flex-column justify-center align-center"
      >
        <v-img
          @click="openTerminalBackOffice"
          width="220px"
          height="227"
          :srcset="`${require(`@/assets/images/search-empty@2x.png`)} 2x`"
          :src="require(`@/assets/images/search-empty.png`)"
        ></v-img>
      </div>
      <div v-else class="d-flex flex-column justify-center align-center">
        <v-img :src="require(`@/assets/images/dish-not-found.svg`)"></v-img>
        <div class="pt-12 tp-text-subtitle-medium-bold">
          {{ $t('screens.search.noResultsTitle') }}
        </div>
        <div class="pt-3 pb-3 tp-text-body">
          {{ $t('screens.search.noResultsSubtitle') }}
        </div>
      </div>
    </LayoutCard>
    <Transition name="opacity">
      <div v-if="isMenuItemSheetOpen" class="menu-item-sheet-overlay"></div>
    </Transition>
    <Transition name="slide-from-bottom">
      <MenuItemSheet
        v-if="isMenuItemSheetOpen"
        v-bind="openedMenuItem"
      ></MenuItemSheet>
    </Transition>
    <MenuControls />
  </div>
</template>

<script>
import _ from 'lodash';
import SearchHeader from '@/components/Navigation/SearchHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import MenuItem from '@/components/MenuItem/MenuItem.vue';
import categoryHasImages from '../helpers/categoryHasImages.js';
import MenuItemSheet from '../components/MenuItem/MenuItemSheet.vue';
import MenuControls from '@/components/MenuControls.vue';

export default {
  components: {
    MenuItem,
    SearchHeader,
    LayoutCard,
    MenuItemSheet,
    MenuControls,
  },
  data() {
    return {
      search: '',
      searchWasUsed: false,
      counter: 0,
    };
  },
  computed: {
    isMenuItemSheetOpen() {
      return this.$store.state.openedMenuItem !== false;
    },
    openedMenuItem() {
      return this.$store.state.items.find(item => {
        return item.id === this.$store.state.openedMenuItem;
      });
    },
    menuItems: function() {
      return this.$store.state.items.filter(item => {
        return (
          this.$t(item.name)
            .toLowerCase()
            .includes(this.$store.state.search.toLowerCase()) ||
          (item.description &&
            this.$t(item.description)
              .toLowerCase()
              .includes(this.$store.state.search.toLowerCase())) ||
          (item.category &&
            this.$t(item.category)
              .toLowerCase()
              .includes(this.$store.state.search.toLowerCase())) ||
          (item.subcategory &&
            this.$t(item.subcategory.name)
              .toLowerCase()
              .includes(this.$store.state.search.toLowerCase()))
        );
      });
    },
    showFullWidthInfo() {
      return !categoryHasImages(this.menuItems);
    },
  },
  watch: {
    search: function() {
      this.$store.dispatch('inputSearch', this.search);
      this.searchWasUsed = true;
      this.sendEventAboutSearch();
    },
    '$store.state.searchOpen': function() {
      this.open = this.$store.state.searchOpen;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    closeSearch() {
      this.$store.dispatch('closeSearch', this.searchWasUsed);
    },
    sendEventAboutSearch: _.debounce(async function() {
      if (this.search) {
        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'search_input',
          search_input: this.search,
        });
      }
    }, 1000),
    // Takes 5 times to click on the search image to open the terminal back office
    openTerminalBackOffice() {
      const isSelfService = this.$store.getters.tableTypeIsSelfService;

      if (!isSelfService) {
        return;
      }

      if (new Date() - this.lastClick < 1000) {
        this.counter++;
        this.lastClick = new Date();
      } else if (!this.lastClick) {
        this.counter++;
        this.lastClick = new Date();
      } else {
        this.counter = 0;
        this.lastClick = new Date();
      }

      if (this.counter === 6) {
        this.$store.dispatch('openTerminalBackOffice');
        this.counter = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  overflow-y: scroll;
  background-color: $color-background-gray;
  padding-top: 100px;
  padding-bottom: 54px;
  min-height: 100vh;

  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
