<template>
  <v-container class="pa-0 mb-8 payment-container">
    <NavHeader
      :title="$t('screens.payment.headerTitle')"
      :subtitle="
        $store.state.restaurant.type === 'restaurant'
          ? $t('screens.payment.headerSubtitle', { tableName })
          : $t('screens.payment.headerSubtitleWithoutTable', { tableName })
      "
      @onBackClick="goBack"
    />
    <LayoutCard v-if="error" rounded>
      <StateScreen
        :title="errorResponseTitle"
        :subtitle="errorResponseDesc"
        :image="require(`@/assets/images/order-failed-image.svg`)"
      />
    </LayoutCard>
    <div v-if="isInitialLoad && !error" class="d-flex justify-center pt-8">
      <v-progress-circular indeterminate color="primary" />
    </div>
    <div v-show="!isInitialLoad && !error">
      <TipPersonnel
        v-if="showTipsPicker"
        :amount="amount"
        :subtotal-amount="subtotal"
        :unique-order-number="uniqueOrderNumber"
        @on-change="changeTips"
      />
      <LayoutCard :title="$t('screens.payment.summaryTitle')" rounded>
        <PriceSummary
          :subtotal-amount="subtotal"
          :total-amount="totalAmount"
          :transaction-fee="transactionFee"
          :tips-amount="tipsAmount"
          :discount-amount="discountAmount"
          :coupon-code="couponCode"
          :small-order-fee="smallOrderFee"
          :amount-being-paid="payGoRef ? amount : null"
          :hide-subtotal="payGoRef"
        />
      </LayoutCard>
      <form id="payment-form">
        <LayoutCard rounded :title="$t('screens.payment.paymentMethods')">
          <PaymentMethods
            ref="paymentMethods"
            :wallet-available="walletAvailable"
            :client-secret="stripePaymentData?.clientSecret"
            @on-change="onPaymentMethodChange"
          />
        </LayoutCard>
        <div>
          <v-alert
            v-if="errorMessage"
            border="left"
            colored-border
            color="red accent-4"
            class="mt-6"
            dismissible
            close-icon="mdi-close"
            >{{ errorMessage }}</v-alert
          >
          <div id="payment-message" class="hidden"></div>
          <v-container class="px-10">
            <div v-show="showPayCta">
              <Button
                ref="CTAButton"
                dark
                :loading="isLoading"
                :disabled="ctaDisabled"
                data-cy="pay-cta"
                @click="onPayCtaPress"
              >
                <div class="menu-item-sheet-controls__add-btn">
                  <div>
                    {{ payCtaLabel }}
                  </div>
                  <div>
                    {{ totalAmountFormatted }}
                  </div>
                </div>
              </Button>
            </div>
            <TermsAndConditions />
          </v-container>
        </div>
      </form>
      <v-overlay :value="backdropVisible">
        <div class="d-flex flex-column justify-center align-center">
          <lottie-vue-player
            :src="backdrop.image"
            style="width: 100px; height:100px; background-color: transparent"
            autoplay
            loop
          />
          <p class="tp-text-subtitle-bold white--text pt-6">
            {{ $t(backdrop.text) }}
          </p>
        </div>
      </v-overlay>
    </div>
    <PisWarning ref="pisWarning" @pay="navigateToNeopay" />
    <PaymentBottomSheetError ref="paymentBottomSheetError" />
  </v-container>
</template>

<script type="application/javascript" src="https://js.stripe.com/v3/"></script>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import Button from '@/components/Button.vue';
import TermsAndConditions from '@/components/common/TermsAndConditions.vue';
import PaymentMethods from '@/components/Payments/PaymentMethods.vue';
import PriceSummary from '@/components/common/PriceSummary.vue';
import TipPersonnel from '@/components/Tips/TipPersonnel.vue';
import StateScreen from '@/components/StateScreen.vue';
import PisWarning from '@/components/Payments/PisWarning.vue';
import PaymentBottomSheetError from '@/components/Payments/PaymentBottomSheetError.vue';

import { PROVIDERS } from '@/utils/constants';

const BACKDROPS = {
  success: {
    text: 'screens.payment.paymentSuccessful',
    image: '/assets/order-status-animations/successful-payment.json',
  },
  loading: {
    text: 'screens.payment.paymentInProgress',
    image: '/assets/order-status-animations/loading.json',
  },
  error: {
    text: 'screens.payment.paymentFailed',
    image: '/assets/order-status-animations/failed-payment.json',
  },
};

let elements;

export default {
  components: {
    LayoutCard,
    Button,
    NavHeader,
    TermsAndConditions,
    PaymentMethods,
    PriceSummary,
    TipPersonnel,
    StateScreen,
    PisWarning,
    PaymentBottomSheetError,
  },
  metaInfo: {
    script: [{ src: 'https://js.stripe.com/v3/', async: false, defer: false }],
  },
  data() {
    return {
      amount: null,
      stripe: undefined,
      hasPaymentIntent: false,
      walletAvailable: false,
      payByCardDisabled: false,
      subtotal: null,
      totalAmount: null,
      transactionFee: null,
      tipsAmount: null,
      discountAmount: null,
      couponCode: null,
      smallOrderFee: null,
      isLoading: false,
      isInitialLoad: true,
      backdropVisible: false,
      backdrop: {},
      error: null,
      errorMessage: null,
      uniqueOrderNumber: null,
      montonioPaymentLink: null,
      neopayPaymentLink: null,
      neopayBlikPaymentLink: null,
      paymentMethod: null,
      stripePaymentData: null,
      montonioPaymentData: null,
      neopayPaymentData: null,
      neopayBlikPaymentData: null,
      stripePaymentRequest: null,
      errorResponseTitle: null,
      errorResponseDesc: null,
      expiresAt: null,
      uuid: null,
      periodicCheckTimer: null,
    };
  },
  created() {
    document.addEventListener(
      'visibilitychange',
      this.checkIfPaymentSessionExpired
    );
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.checkIfPaymentSessionExpired
    );
    clearInterval(this.periodicCheckTimer);
  },
  computed: {
    showPayCta() {
      return (
        this.paymentMethod === PROVIDERS.STRIPE ||
        this.paymentMethod === PROVIDERS.NEOPAY ||
        this.paymentMethod === PROVIDERS.NEOPAY_BLIK
      );
    },
    payCtaLabel() {
      return this.$t('screens.payment.payCtaLabel');
    },
    ctaDisabled() {
      if (this.paymentMethod === PROVIDERS.STRIPE) {
        return this.payByCardDisabled;
      } else if (
        [PROVIDERS.MONTONIO, PROVIDERS.NEOPAY, PROVIDERS.NEOPAY_BLIK].includes(
          this.paymentMethod
        )
      ) {
        return false;
      }

      return true;
    },
    tableName() {
      return this.$store.state.table.name;
    },
    returnUrl() {
      const payGoRedirectUrl = `${process.env.VUE_APP_BASE_URL}/success/${this.$store.state.session}?orderNumber=${this.uniqueOrderNumber}`;
      const orderRedirectUrl = `${process.env.VUE_APP_BASE_URL}/menu/${this.$store.state.session}?order=${this.uniqueOrderNumber}`;

      if (this.orderEnabled && this.payGoEnabled && this.payGoRef) {
        return payGoRedirectUrl;
      } else if (this.orderEnabled && this.payGoEnabled) {
        return orderRedirectUrl;
      } else if (this.payGoEnabled) {
        return payGoRedirectUrl;
      }

      return orderRedirectUrl;
    },
    totalAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.totalAmount);
    },
    payGoEnabled() {
      return this.$store.getters.functionalityPayGoEnabled;
    },
    payGoRef() {
      const ref = this.$route.query.ref;
      return ref === 'select-items' || ref === 'order';
    },
    orderEnabled() {
      return this.$store.getters.functionalityOrderEnabled;
    },
    ordersDisabled() {
      return !this.orderEnabled && !this.payGoEnabled;
    },
    orderAlreadyPaid() {
      return this.$store.getters.getIsOrderAlreadyPaid(
        this.$route.params.order
      );
    },
    orderHasPersonnel() {
      const order = this.$store.getters.getPOSOrderByUniqueOrderNumber(
        this.$route.params.order
      );
      return !!order?.personnel;
    },
    showTipsPicker() {
      // If hybrid mode is enabled, we should not use functionalitySendTipsToPosWaiterEnabled
      if (this.orderEnabled && this.payGoEnabled && !this.payGoRef) {
        return false;
      }
      if (this.$store.getters.functionalitySendTipsToPosWaiterEnabled) {
        return this.payGoEnabled && this.orderHasPersonnel;
      }
      return this.payGoEnabled;
    },
    countryCode() {
      return this.$store.getters.restaurantCountryCode;
    },
  },
  watch: {
    async orderAlreadyPaid(isPaid) {
      if (isPaid && this.payGoEnabled) {
        await this.navigateToOrder();
      }
    },
  },
  async mounted() {
    this.isInitialLoad = true;
    const sessionToken = this.$route.params.session;
    window.scrollTo({ top: 0 });

    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.$store.dispatch('fetchOrders');

    if (this.ordersDisabled) {
      this.$router.push({
        name: 'Menu',
        params: { session: sessionToken },
      });
      return;
    }

    if (this.orderAlreadyPaid) {
      return this.navigateToOrder();
    }

    await Promise.all([
      this.loadStripeIfNeeded(),
      this.generateStripePaymentIntent({ session: sessionToken }),
      this.createMontonioOrder({ session: sessionToken }),
      this.createNeopayTransaction({ session: sessionToken }),
      this.createNeopayBlikTransaction({ session: sessionToken }),
    ]);
    this.checkIfPaymentSessionExpired();

    // ASG INTEGRATION
    this.$store.dispatch('subscribeToExternalPaymentStatus', {
      onSuccess: this.goToSuccess,
      onError: () => this.showBackdrop('error', 2000),
    });

    await this.loadStripeElements();

    this.isInitialLoad = false;

    this.periodicCheckTimer = setInterval(() => {
      this.checkIfPaymentSessionExpired();
    }, 10000);
  },
  methods: {
    async loadStripeIfNeeded() {
      if (!this.stripe) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK || '');
      }
    },
    async changeTips({ personnelId, tipAmount = 0 }) {
      if (tipAmount === null) {
        return;
      }

      this.isLoading = true;

      await Promise.all([
        this.generateStripePaymentIntent({
          personnelId,
          tipAmount,
        }),
        this.createMontonioOrder({
          personnelId,
          tipAmount,
        }),
        this.createNeopayTransaction({
          personnelId,
          tipAmount,
        }),
        this.createNeopayBlikTransaction({
          personnelId,
          tipAmount,
        }),
      ]);

      await this.loadStripeElements();

      this.isLoading = false;
    },
    async loadStripeElements() {
      if (this.error) {
        return;
      }
      this.stripePaymentRequest = this.stripe.paymentRequest({
        country: this.countryCode,
        currency: this?.stripePaymentData?.currency,
        total: {
          label: `the bill at ${this.$store.state.restaurant.name}`,
          amount: Math.round(this?.stripePaymentData?.total * 100), // in cents
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });

      const options = {
        clientSecret: this?.stripePaymentData.clientSecret,
        locale: this.countryCode,
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: ' "Poppins", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: '10px',
            colorBackground: '#F6F8FA',
            colorPrimaryText: '#262626',
            colorPrimary: '#E85941',
          },
        },
      };

      const paymentElementOptions = {
        wallets: {
          applePay: 'never',
          googlePay: 'never',
        },
      };

      elements = this.stripe.elements(options);
      const card = elements.create('payment', paymentElementOptions);
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: this.stripePaymentRequest,
      });

      if (this.$refs?.paymentMethods?.$refs?.card) {
        try {
          card.mount(this.$refs.paymentMethods.$refs.card);
        } catch (error) {
          this.payByCardDisabled = true;
        }
      }

      const result = await this.stripePaymentRequest.canMakePayment();
      if (result && this.$refs?.paymentMethods?.$refs?.prButton) {
        prButton.mount(this.$refs.paymentMethods.$refs.prButton);
        this.walletAvailable = true;
      } else {
        this.walletAvailable = false;
        console.log('Cannot make payment');
      }

      this.stripePaymentRequest.on('paymentmethod', async e => {
        const {
          paymentIntent,
          error: confirmError,
        } = await this.stripe.confirmCardPayment(
          this.stripePaymentData.clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (confirmError) {
          e.complete('fail');
          this?.$refs?.paymentBottomSheetError?.open(confirmError);
          this.showMessage(confirmError.message);

          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'purchase_failed',
            transaction_id: this?.uniqueOrderNumber,
            message: confirmError.message,
          });

          return;
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          e.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent && paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await this.stripe.confirmCardPayment(
              this.stripePaymentData.clientSecret
            );
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              this.walletAvailable = false;
              this?.$refs?.paymentBottomSheetError?.open(error);
              this.showMessage(
                'Payment failed. Please try another payment method.'
              );

              this.$store.dispatch('sendAnalyticsEvent', {
                event: 'purchase_failed',
                transaction_id: this?.uniqueOrderNumber,
              });
            } else {
              // The payment has succeeded.
              this.goToSuccess();
            }
          } else {
            // The payment has succeeded.
            this.goToSuccess();
          }
        }
      });
    },
    navigateToMontonio() {
      if (this.montonioPaymentLink) {
        this.isLoading = true;
        window.location = this.montonioPaymentLink;
      }
    },
    navigateToNeopay() {
      if (this.neopayPaymentLink) {
        this.isLoading = true;
        window.location = this.neopayPaymentLink;
      }
    },
    navigateToNeopayBlik() {
      if (this.neopayBlikPaymentLink) {
        this.isLoading = true;
        window.location = this.neopayBlikPaymentLink;
      }
    },
    async generateStripePaymentIntent({
      personnelId = null,
      tipAmount = null,
      session = this.$store.state.session,
    } = {}) {
      const tips = tipAmount || this.$store.getters.tipAmount;
      try {
        const paymentRequest = await this.axios.post(
          `/payments/stripe/payment-intent`,
          {
            session,
            order: this.$route.params.order,
            personnel_id: personnelId,
            tips: tips * 100,
            pay_for_selected_items:
              this.$route.query.payForSelectedItems || false,
          }
        );
        if (paymentRequest.data.total) {
          this.hasPaymentIntent = true;
          this.stripePaymentData = paymentRequest.data;
          this.expiresAt = this.stripePaymentData.expires_at;
          this.subtotal = this.stripePaymentData.subtotal;
          this.totalAmount = this.stripePaymentData.total;
          this.transactionFee = this.stripePaymentData.transaction_fee;
          this.tipsAmount = this.stripePaymentData.tips;
          this.discountAmount = this.stripePaymentData.discount;
          this.couponCode = this.stripePaymentData.coupon;
          this.uniqueOrderNumber = this.stripePaymentData.unique_order_number;
          this.smallOrderFee = this.stripePaymentData?.small_order_fee || 0;
          this.amount = this.stripePaymentData.amount;
          this.uuid = this.stripePaymentData.uuid;
        } else {
          this.error = true;
        }
        return;
      } catch (error) {
        this.handleError(error);
      }
    },
    async createMontonioOrder({
      personnelId = null,
      tipAmount = null,
      session = this.$store.state.session,
    } = {}) {
      const montonioEnabled = this.$store.getters
        .paymentProviderMontonioEnabled;
      if (montonioEnabled) {
        try {
          const tips = tipAmount || this.$store.getters.tipAmount;
          const paymentRequest = await this.axios.post(
            `/payments/montonio/create-order`,
            {
              session,
              order: this.$route.params.order,
              personnel_id: personnelId,
              pay_for_selected_items:
                this.$route.query.payForSelectedItems || false,
              tips: tips * 100,
            }
          );

          this.montonioPaymentData = paymentRequest.data;
          this.montonioPaymentLink = paymentRequest.data.redirect_url;
          return;
        } catch (error) {
          this.handleError(error);
        }
      }
    },
    async createNeopayTransaction({
      personnelId = null,
      tipAmount = this.$store.getters.tipAmount,
      session = this.$store.state.session,
    } = {}) {
      const neopayEnabled = this.$store.getters.paymentProviderNeopayEnabled;
      if (neopayEnabled) {
        try {
          const tips = tipAmount;
          const paymentRequest = await this.axios.post(
            `/payments/neopay/create-transaction`,
            {
              session,
              order: this.$route.params.order,
              personnel_id: personnelId,
              pay_for_selected_items:
                this.$route.query.payForSelectedItems || false,
              tips: tips * 100,
            }
          );

          this.neopayPaymentData = paymentRequest.data;
          this.neopayPaymentLink = paymentRequest.data.redirect_url;
          return;
        } catch (error) {
          this.handleError(error);
        }
      }
    },
    async createNeopayBlikTransaction({
      personnelId = null,
      tipAmount = this.$store.getters.tipAmount,
      session = this.$store.state.session,
    } = {}) {
      const neopayBlikEnabled = this.$store.getters
        .paymentProviderNeopayBlikEnabled;
      if (neopayBlikEnabled) {
        try {
          const tips = tipAmount;
          const paymentRequest = await this.axios.post(
            `/payments/neopay/create-blik-transaction`,
            {
              session,
              order: this.$route.params.order,
              personnel_id: personnelId,
              pay_for_selected_items:
                this.$route.query.payForSelectedItems || false,
              tips: tips * 100,
            }
          );

          this.neopayBlikPaymentData = paymentRequest.data;
          this.neopayBlikPaymentLink = paymentRequest.data.redirect_url;
          return;
        } catch (error) {
          this.handleError(error);
        }
      }
    },
    handleError(error) {
      if (error.response.data.message === 'Order is already paid') {
        this.error = true;
        return this.navigateToOrder();
      } else if (
        error.response.data.message ===
        'Order already has payments of other type'
      ) {
        this.error = true;
        this.errorResponseTitle = this.$t(
          'screens.payment.error.partAlreadyPaid.title'
        );
        this.errorResponseDesc = this.$t(
          'screens.payment.error.partAlreadyPaid.description'
        );
      }
    },
    async payByCard(e) {
      e.preventDefault();

      this.isLoading = true;
      this.payByCardDisabled = true;
      this.showBackdrop('loading');

      const { error } = await this.stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: this.returnUrl,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this?.$refs?.paymentBottomSheetError?.open(error);
        this.showMessage(error.message);

        this.$store.dispatch('sendAnalyticsEvent', {
          event: 'purchase_failed',
          transaction_id: this?.uniqueOrderNumber,
          message: error.message,
        });
      } else {
        this?.$refs?.paymentBottomSheetError?.open(error);
        this.showMessage('An unexpected error occurred.');
      }

      if (!error) {
        this.sendPurchaseAnalytics('card');
      }

      this.isLoading = false;
      this.payByCardDisabled = false;
      this.hideBackdrop();
    },
    goBack() {
      this.$router.go(-1);
    },
    showMessage(message) {
      this.errorMessage = message;
    },
    async showBackdrop(backdrop, timeout) {
      await this.hideBackdrop(); // hide previous backdrop
      this.backdropVisible = true;
      this.backdrop = BACKDROPS[backdrop];

      if (timeout) {
        await new Promise(resolve => setTimeout(resolve, timeout));
        this.hideBackdrop();
      }
    },
    hideBackdrop() {
      this.backdropVisible = false;
      this.backdrop = {};
    },
    async goToSuccess() {
      await this.showBackdrop('success', 2000);

      this.sendPurchaseAnalytics('wallet');

      if (this.orderEnabled && this.payGoEnabled && this.payGoRef) {
        return this.goToPaymentSuccess();
      } else if (this.orderEnabled && this.payGoEnabled) {
        return this.navigateToOrder();
      } else if (this.payGoEnabled) {
        return this.goToPaymentSuccess();
      }

      this.navigateToOrder();
    },
    async navigateToOrder() {
      if (this.payGoEnabled && !this.orderEnabled) {
        this.$router.replace({
          name: 'Order',
          query: {
            orderNumber: this.$route.params.order,
          },
          params: { session: this.$store.state.session },
        });
        return;
      }
      this.$store.dispatch('fetchOrders');
      this.$store.dispatch('openOrder', this.uniqueOrderNumber);
      this.$router.push({
        name: 'Menu',
        query: {
          order: this.uniqueOrderNumber || this.$route.params.order,
        },
        params: {
          session: this.$store.state.session,
        },
      });
    },
    async goToPaymentSuccess() {
      this.$router.push({
        name: 'PaymentSuccess',
        query: {
          orderNumber: this.uniqueOrderNumber,
        },
        params: {
          session: this.$store.state.session,
        },
      });
    },
    onPaymentMethodChange(method) {
      this.paymentMethod = method;

      if (method === PROVIDERS.MONTONIO) {
        setTimeout(this.scrollToPayCTA, 300);
        this.subtotal = this.montonioPaymentData.subtotal;
        this.totalAmount = this.montonioPaymentData.total;
        this.transactionFee = this.montonioPaymentData.transaction_fee;
        this.tipsAmount = this.montonioPaymentData.tips;
        this.smallOrderFee = this.montonioPaymentData?.small_order_fee || 0;
      } else if (method === PROVIDERS.STRIPE) {
        setTimeout(this.scrollToPayCTA, 300);
        this.subtotal = this.stripePaymentData.subtotal;
        this.totalAmount = this.stripePaymentData.total;
        this.transactionFee = this.stripePaymentData.transaction_fee;
        this.tipsAmount = this.stripePaymentData.tips;
        this.smallOrderFee = this.stripePaymentData?.small_order_fee || 0;
      } else if (method === PROVIDERS.NEOPAY) {
        setTimeout(this.scrollToPayCTA, 300);
        this.subtotal = this.neopayPaymentData.subtotal;
        this.totalAmount = this.neopayPaymentData.total;
        this.transactionFee = this.neopayPaymentData.transaction_fee;
        this.tipsAmount = this.neopayPaymentData.tips;
        this.smallOrderFee = this.neopayPaymentData?.small_order_fee || 0;
      } else if (method === PROVIDERS.NEOPAY_BLIK) {
        setTimeout(this.scrollToPayCTA, 300);
        this.subtotal = this.neopayBlikPaymentData.subtotal;
        this.totalAmount = this.neopayBlikPaymentData.total;
        this.transactionFee = this.neopayBlikPaymentData.transaction_fee;
        this.tipsAmount = this.neopayBlikPaymentData.tips;
        this.smallOrderFee = this.neopayBlikPaymentData?.small_order_fee || 0;
      }
    },
    onPayCtaPress(e) {
      e.preventDefault();
      if (this.paymentMethod === PROVIDERS.STRIPE) {
        this.payByCard(e);
      } else if (this.paymentMethod === PROVIDERS.MONTONIO) {
        this.sendPurchaseAnalytics(PROVIDERS.MONTONIO);
        this.navigateToMontonio();
      } else if (this.paymentMethod === PROVIDERS.NEOPAY) {
        this.sendPurchaseAnalytics(PROVIDERS.NEOPAY);

        // show warning to make sure user is redirected back to the app
        if (this.payGoRef) {
          return this.$refs.pisWarning.open();
        }

        this.navigateToNeopay();
      } else if (this.paymentMethod === PROVIDERS.NEOPAY_BLIK) {
        this.sendPurchaseAnalytics(PROVIDERS.NEOPAY_BLIK);
        this.navigateToNeopayBlik();
      }
    },
    sendPurchaseAnalytics(method) {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'purchase',
        transaction_id: this?.uniqueOrderNumber,
        value: this?.totalAmount,
        currency: this.$store.getters.getCurrencyCode,
        method,
      });
    },
    async scrollToPayCTA() {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.$refs.CTAButton.$el.scrollIntoView({ behavior: 'smooth' });
    },
    async checkIfPaymentSessionExpired() {
      const hidden = document.hidden;
      const notPaymentScreen = this.$route.name !== 'Pay';
      const loading = this.isLoading;
      const backdropVisible = this.backdropVisible;

      if (hidden || notPaymentScreen || loading || backdropVisible) {
        return;
      }

      const onExpired = () => {
        if (hidden || notPaymentScreen || loading || backdropVisible) {
          return;
        }

        this.goBack();
        this.$store.dispatch('showToastInstantly', {
          message: this.$t('screens.payment.error.paymentSessionExpired'),
        });
      };
      try {
        if (this.uuid) {
          await this.$store.dispatch('checkIfPaymentIntentExpired', {
            paymentUuid: this.uuid,
            onExpired,
          });
        }
        if (this.neopayPaymentData?.uuid) {
          await this.$store.dispatch('checkIfPaymentIntentExpired', {
            paymentUuid: this.neopayPaymentData?.uuid,
            onExpired,
          });
        }
        if (this.neopayBlikPaymentData?.uuid) {
          await this.$store.dispatch('checkIfPaymentIntentExpired', {
            paymentUuid: this.neopayBlikPaymentData?.uuid,
            onExpired,
          });
        }
      } catch (error) {
        console.log(error);
        onExpired();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-container {
  background-color: $color-background-gray;
  height: 100%;
}
</style>
